import {
  getBalanceReport,
  GetBalanceReportData,
  getGuestsStream,
  getReports,
} from "api/managerReports";
import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { RootStore } from "store/rootStore";
import {
  IFinDividend,
  IFinResultArticle,
  IGuestsStream,
  IRevenueExpenseArticle,
} from "types/manager-reports";

export class ManagerReportsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ManagerReportsStore",
      properties: ["activeTab"],
      storage: window.localStorage,
      stringify: true,
    });
    this.rootStore = rootStore;
  }

  activeTab: 1 | 2 | 3 | 4 | 5 = 1;
  setActiveTab = (id: typeof this.activeTab) => {
    this.activeTab = id;
  };

  reportsIsLoading = false;
  setReportsIsLoading = (data: typeof this.reportsIsLoading) => (this.reportsIsLoading = data);

  actualDate: string | null = null;
  private setActualDate = (data: typeof this.actualDate) => (this.actualDate = data);
  //YYYY-MM-DDTHH:mm:ss.000Z
  finResults: IFinResultArticle[] | null = null;
  private setFinResults = (data: typeof this.finResults) => (this.finResults = data);
  revenueExpenses: IRevenueExpenseArticle[] | null = null;
  private setRevenueExpenses = (data: typeof this.revenueExpenses) => (this.revenueExpenses = data);
  dividends: IFinDividend[] | null = null;
  private setDividends = (data: typeof this.dividends) => (this.dividends = data);
  balance: GetBalanceReportData | null = null;
  private setBalance = (data: typeof this.balance) => (this.balance = data);

  guestsStream: IGuestsStream | null = null;
  private setGuestsStream = (data: typeof this.guestsStream) => (this.guestsStream = data);

  fetchGetReports = async () => {
    this.setReportsIsLoading(true);
    const response = await getReports();
    if (!response) {
      this.setActualDate(null);
      this.setFinResults([]);
      this.setRevenueExpenses([]);
      this.setDividends([]);
      this.setReportsIsLoading(false);
      return;
    }

    this.setActualDate(response.actual_date);
    this.setFinResults(response.fin_result);
    this.setRevenueExpenses(response.revenue_expenses);
    this.setDividends(response.dividends);
    this.setReportsIsLoading(false);
  };

  fetchGetBalance = async () => {
    this.setReportsIsLoading(true);
    const response = await getBalanceReport();
    if (!response) {
      this.setBalance(null);
      this.setReportsIsLoading(false);
      return;
    }
    this.setBalance(response);
    this.setReportsIsLoading(false);
  };

  fetchGetGuestsStream = async () => {
    this.setReportsIsLoading(true);
    const response = await getGuestsStream();
    if (!response) {
      this.setGuestsStream(null);
      this.setReportsIsLoading(false);
      return;
    }
    this.setGuestsStream(response);
    this.setReportsIsLoading(false);
  };
}
