import {
  IBalanceArticle,
  IFinDividend,
  IFinResultArticle,
  IGuestsStream,
  IRevenueExpenseArticle,
} from "types/manager-reports";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { onError } from "./api-new";
import { getReportsData } from "_mocks/manager-reports/api-responses";
import { mockGuestsStream } from "_mocks/manager-reports/guests";

const IS_MOCK = false;

export interface GetReportsData {
  //DD.MM.YYYY
  actual_date: string;
  //YYYY-MM-DDTHH:mm:ss.000Z
  fin_result: IFinResultArticle[];
  revenue_expenses: IRevenueExpenseArticle[];
  dividends: IFinDividend[];
}

export const getReports = async () => {
  try {
    if (IS_MOCK) {
      return await new Promise<GetReportsData>((resolve, reject) => {
        setTimeout(() => {
          const random = Math.random();
          if (random < 0.3) reject("Тестовая ошибка");
          resolve({ ...getReportsData });
        }, 800);
      });
    }
    const response = await api.get<IBaseResponse<GetReportsData>>(`reports/manager/reports`);
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
};

export interface GetBalanceReportData {
  active: IBalanceArticle[];
  passive: IBalanceArticle[];
  delta: IBalanceArticle[];
}

export const getBalanceReport = async () => {
  try {
    const response = await api.get<IBaseResponse<GetBalanceReportData>>(`reports/manager/balance`);
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
};

export const getGuestsStream = async () => {
  try {
    if (IS_MOCK)
      return await new Promise<IGuestsStream>((resolve, reject) => {
        setTimeout(() => {
          resolve({ ...mockGuestsStream });
        }, 800);
      });

    const response = await api.get<IBaseResponse<IGuestsStream>>("reports/manager/guests");
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
};
