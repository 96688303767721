import {
  IEmployee,
  INewShift,
  INewShiftWithTemplate,
  IShift,
  IShiftUpdate,
  ITemplate,
  ITemplateNew,
  ITemplateUpdate,
  ITimeTable,
} from "pages/spot/schedule-planning-new/types";
import { IBaseResponse } from "./api";
import { api, onError } from "./api-new";

const BASE_URL = `shifts/`;
export const BASE_API_URL_V1 = `${process.env.REACT_APP_API_BASE}/api/web/v1/`;

//#region Шаблоны
export async function getTemplates() {
  try {
    const response = await api.get<IBaseResponse<ITemplate[]>>(
      `${BASE_API_URL_V1}${BASE_URL}template/list`
    );
    return response.data.data;
  } catch (error) {
    return 0;
  }
}

export async function createTemplate(template: ITemplateNew) {
  try {
    const response = await api.post<IBaseResponse<ITemplate>>(
      `${BASE_API_URL_V1}${BASE_URL}template/create`,
      template
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateTemplate(template: ITemplateUpdate) {
  try {
    const response = await api.post<IBaseResponse<ITemplate>>(
      `${BASE_API_URL_V1}${BASE_URL}template/update`,
      template
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function deleteTemplate(templateId: ITemplate["id"]) {
  try {
    await api.post<IBaseResponse<any>>(`${BASE_API_URL_V1}${BASE_URL}template/delete`, {
      template_id: templateId,
    });
    return 1;
  } catch (error) {
    return onError(error);
  }
}
//#endregion

//#region Смены

//date: YYYY-MM
export async function getTimetable(date: string) {
  try {
    const response = await api.post<IBaseResponse<ITimeTable>>(`${BASE_API_URL_V1}timetable/list`, {
      date,
    });

    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

//TODO: Добавить интерфейс под эту апи, здесь приходят только результаты всех
export async function getResults(date: string) {
  try {
    const response = await api.post<IBaseResponse<ITimeTable>>(
      `${BASE_API_URL_V1}timetable/results`,
      { date }
    );

    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function createShift(shift: INewShiftWithTemplate | INewShift) {
  try {
    const response = await api.post<IBaseResponse<IShift>>(
      `${BASE_API_URL_V1}${BASE_URL}create`,
      shift
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateShift(shift: IShiftUpdate) {
  try {
    const response = await api.post<IBaseResponse<IShift>>(
      `${BASE_API_URL_V1}${BASE_URL}update`,
      shift
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function deleteShift(shift_id: IShift["id"]) {
  try {
    await api.post(`${BASE_API_URL_V1}${BASE_URL}delete`, { shift_id });
    return 1;
  } catch (error) {
    return onError(error);
  }
}
//#endregion

//#region Хелперы

export async function getEmployees() {
  try {
    const response = await api.get<IBaseResponse<IEmployee[]>>(`employees`);

    return response.data.data;
  } catch (error) {
    return 0;
  }
}

//date: YYYY-MM
export async function addHelper(user_id: IEmployee["id"], date: string) {
  try {
    const response = await api.post<
      IBaseResponse<{ id: number; user_id: number; spot_id: number }>
    >(`timetable/helpers/add`, { user_id, date });
    return response.data;
  } catch (error) {
    return onError(error);
  }
}

export async function deleteHelper(user_id: IEmployee["id"], date: string) {
  try {
    await api.post<IBaseResponse<[]>>(`timetable/helpers/delete`, {
      user_id,
      date,
    });
    return 1;
  } catch (error) {
    return onError(error);
  }
}
//#endregion
