import { IGuestsStream } from "types/manager-reports";

const ARRAY_LENGTH = 24;

const ACTUAL_DATE: IGuestsStream["actual_date"] = "01.04.2024";
const LAST_DATE: IGuestsStream["last_date"] = "01.03.2024";
const PERIOD: IGuestsStream["period"] = Array.from(
  { length: ARRAY_LENGTH },
  (_, i) => `${i.toString().padStart(2, "0")}:00`
);

const GUESTS_DATA = () =>
  Array.from({ length: ARRAY_LENGTH }, () => Math.floor(Math.random() * 351));

export const mockGuestsStream: IGuestsStream = {
  actual_date: ACTUAL_DATE,
  last_date: LAST_DATE,
  period: PERIOD,
  mar: GUESTS_DATA(),
  apr: GUESTS_DATA(),
};
