import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo } from "react";
import cls from "./styles.module.scss";
import dayjs from "dayjs";
import { useStore } from "store";
import { InfoMessage } from "widgets/info-message";

const TAB_TITLES = [
  "Финансовый результат",
  "Выручка и затраты",
  "Финансовая деятельность",
  "Баланс",
  "Гостевой поток",
];

interface ManagerReportsHeaderProps {}

const ManagerReportsHeaderComponent: FC<ManagerReportsHeaderProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: { actualDate, finResults, revenueExpenses, dividends },
    },
  } = useStore();

  const reportsNotFormed = useMemo(
    () => [finResults?.length, revenueExpenses?.length, dividends?.length].every(num => num === 0),
    //TODO вернуть проверку || !lastUpdateDate когда появится это поле в апи ,
    [dividends?.length, finResults?.length, revenueExpenses?.length]
  );

  //TODO Добавить внутрь dayjs параметра lastUpdateDate когда появится поле в апи
  const infoMessage = useMemo(() => {
    if (reportsNotFormed)
      return (
        <p key={"reports-info-message"}>
          Для формирования отчета необходим хотя бы один закрытый период
        </p>
      );
    return null;
  }, [reportsNotFormed]);

  const tabs = useMemo(
    () => TAB_TITLES.map((t, i) => <Tab title={t} index={i + 1} key={"tab-" + i + 1} />),
    []
  );

  return (
    <div className={cls["page-header"]}>
      {infoMessage && (
        <div style={{ alignSelf: "flex-end", marginBottom: "-16px" }}>
          <InfoMessage width="fit-content" height="fit-content" messages={[infoMessage]} />
        </div>
      )}
      <div className={cls["row"]}>
        <h1 className={cls["page-title"]}>
          Отчет управляющего
          {actualDate && (
            <>
              {` за `}
              <span>{dayjs(actualDate, "DD.MM.YYYY").format("MMMM")}</span>
            </>
          )}
        </h1>
      </div>
      <ul className={cls["tabs"]}>{tabs}</ul>
    </div>
  );
};

interface TabProps {
  title: string;
  index: number;
}

const Tab: FC<TabProps> = observer(({ title, index }) => {
  const {
    RootStore: {
      ManagerReportsStore: { activeTab, setActiveTab },
    },
  } = useStore();

  const isActive = useCallback(
    (id: number) => (activeTab === id ? cls["active"] : ""),
    [activeTab]
  );

  return (
    <li
      id={`tab-${index}`}
      onClick={() => setActiveTab(index as any)}
      className={`${cls["tab"]} ${isActive(index)}`}
    >
      {title}
    </li>
  );
});

export const ManagerReportsHeader = observer(ManagerReportsHeaderComponent);
