import { FC } from "react";
import dayjs from "dayjs";
import { TooltipProps } from "recharts";
import { addSpaces } from "assets/utils";
import cls from "./styles.module.scss";
import { STROKES } from "utils/const/manager-reports/guest-stream-colors";

interface CustomTooltipProps
  extends TooltipProps<string | number | (string | number)[], string | number> {}

export const CustomTooltipContent: FC<CustomTooltipProps> = ({ payload, label }) => {
  if (payload && payload.length > 0) {
    return (
      <div className={cls["chart-tooltip"]}>
        <p className={cls["label"]}>{label}</p>
        {payload?.map((p: any, i) => (
          <p className={cls["content"]}>
            {dayjs(`2025-${p.name}-01`).format("MMMM")}:
            <span style={{ color: STROKES[i] }}> {addSpaces(p.value)}</span>
          </p>
        ))}
      </div>
    );
  }
  return null;
};
