import dayjs from "dayjs";
import { Month } from "types/directories";
import { MONTHS_IN_YEAR } from "utils/const/shared/date";
import en from "dayjs/locale/en";

export function monthsMap(callback: (month: Month, i: number) => any) {
  return Array.from(Array(MONTHS_IN_YEAR).keys()).map((k, i) => {
    let month = dayjs().locale(en).month(k).format("MMM").toLowerCase();
    return callback(month as Month, i);
  });
}

export const get12MonthsFromCurrent = (actualDate: string | undefined): string[] => {
  let baseDate = !actualDate ? dayjs() : dayjs(actualDate, "DD.MM.YYYY");

  // Проверка валидности даты
  if (!baseDate.isValid()) {
    console.error("Invalid actualDate:", actualDate);
    baseDate = dayjs();
  }

  return Array.from({ length: 12 }, (_, index) => {
    const monthDate = baseDate.subtract(index + 1, "month");
    return monthDate.isValid() ? monthDate.locale("en").format("MMM").toLowerCase() : "invalid";
  });
};
