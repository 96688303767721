import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useStore } from "store";
import { IGuestsStreamData } from "types/manager-reports";
import "pages/dashboard/styles.scss";
import { toJS } from "mobx";
import cls from "./styles.module.scss";
import { get12MonthsFromCurrent, monthsMap } from "utils/functions/mapMonths";
import dayjs from "dayjs";
import { CustomTooltipContent } from "./tooltip";
import { FILLS, STROKES } from "utils/const/manager-reports/guest-stream-colors";
import { CustomTick } from "pages/dashboard/components/tick";

interface GuestsProps {}

const GuestsComponent: FC<GuestsProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: { guestsStream },
    },
  } = useStore();

  const orderedMonths = useMemo(
    () => get12MonthsFromCurrent(guestsStream?.actual_date),
    [guestsStream?.actual_date]
  );

  const data: IGuestsStreamData[] = useMemo(() => {
    if (!guestsStream) return [];
    let data: IGuestsStreamData[] = toJS(guestsStream).period.map((period, i) => {
      const months = monthsMap(month => {
        return toJS(guestsStream[month])?.[i] ?? null;
      }).reduce(
        (acc, month, index) =>
          month
            ? { ...acc, [dayjs().month(index).locale("en").format("MMM").toLowerCase()]: month }
            : acc,
        {}
      );
      return {
        period,
        ...months,
      };
    });

    return data;
  }, [guestsStream]);

  const areas = useMemo(() => {
    return orderedMonths.map((month, index) => {
      return (
        <Area
          connectNulls
          type="monotone"
          dataKey={month}
          stroke={STROKES[index] ?? "#999292"}
          fill={FILLS[index] ?? "#f5f5f5"}
        />
      );
    });
  }, [orderedMonths]);

  return (
    <div className={`${cls["wrapper"]}`}>
      <Legend orderedMonths={orderedMonths} />
      <div className={cls["chart-container"]}>
        {/* Порядок компонентов внутри графика имеет большое значение. */}
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart width={500} height={400} data={data ?? []}>
            <CartesianGrid vertical={true} />
            <XAxis
              dataKey={"period"}
              axisLine={false}
              tickLine={false}
              xAxisId={0}
              tick={<CustomTick />}
            />
            <XAxis dataKey={"id"} visibility={"hidden"} xAxisId={1} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip
              position={{ y: 150 }}
              wrapperStyle={{ visibility: "visible", height: "100%" }}
              content={<CustomTooltipContent />}
            />
            {areas}
          </AreaChart>
        </ResponsiveContainer>
      </div>
      {/* {activeId !== null && <button className='active-date' onClick={() => setActiveId(null)}>Снять выделение</button>} */}
    </div>
  );
};

const Legend: FC<{ orderedMonths: string[] }> = ({ orderedMonths }) => {
  const itemsData = useMemo(() => {
    return [orderedMonths[0], orderedMonths[1]].map((m, i) => (
      <div className={cls["legend__item"]}>
        <span style={{ backgroundColor: STROKES[i], opacity: 0.5 }} />
        <p>{dayjs(`2025-${m}-01`).format("MMMM")}</p>
      </div>
    ));
  }, [orderedMonths]);

  return <div className={cls["legend"]}>{itemsData}</div>;
};

export const Guests = observer(GuestsComponent);
